import type { LineItem } from '@commerce/types/cart'
import { useUI } from '@components/ui/context'
import useCart from '@framework/cart/use-cart'
import { RoundButton } from '@components/ui'
import { FaShoppingCart } from 'react-icons/fa'
import * as analytics from '@lib/analytics'
import cn from 'clsx'

interface BasketIconProps {
  itemsCount: number
}

interface BasketProps {
  isProductPage?: boolean
}

const countItem = (count: number, item: LineItem) => count + item.quantity

export const BasketIcon = ({ itemsCount }: BasketIconProps) => (
  <>
    <FaShoppingCart className="m-2 h-6 w-6" />
    <span data-cy="basket" className="absolute top-3.5 flex h-[1rem] w-[1rem] items-center 
    justify-center rounded-full border border-base-100 bg-accent-100 text-xs font-bold text-black">
      {itemsCount}
    </span>
  </>
)

const Basket = ({ isProductPage }: BasketProps) => {
  const { data } = useCart()
  const { toggleSidebar, setSidebarView } = useUI()
  const itemsCount = data?.lineItems.reduce(countItem, 0) ?? 0

  const handleClick = () => {
    setSidebarView('CART_VIEW')
    toggleSidebar()
    analytics.track('Go to basket', { itemsCount })
  }

  const icon = () => <BasketIcon itemsCount={itemsCount} />

  return !!itemsCount ? (
    <RoundButton
      className={cn('fixed right-2 bottom-20 z-[12000] h-20 w-20', { 'sm:right-[50.4%] md:right-[45.4%] xl:right-[40.4%]': isProductPage })}
      onClick={handleClick}
      icon={icon}
      ariaLabel="Open basket"
    />
  ) : null
}

export default Basket
