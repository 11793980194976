import { getApiConfig } from './cart';
import { handleFetchResponse } from './utils';
const fetcher = async ({ method ='POST' , variables , query  })=>{
    const { locale , ...vars } = variables ?? {};
    const { apiUrl , apiToken  } = getApiConfig();
    return handleFetchResponse(await fetch(`https://${apiUrl}/api/2022-07/graphql.json`, {
        method,
        body: JSON.stringify({
            query,
            variables: vars
        }),
        headers: {
            'X-Shopify-Storefront-Access-Token': apiToken,
            'Content-Type': 'application/json',
            ...locale && {
                'Accept-Language': locale
            }
        }
    }));
};
export default fetcher;
