const Shopcat = ({ ...props }) => {
  return (
    <svg
      width="523"
      height="107"
      viewBox="0 0 523 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M158.801 81.3382C167.049 80.8095 173.075 77.003 176.988 69.9187C179.948 64.3147 180.583 58.605 178.785 52.7895C176.988 46.8683 173.393 42.4274 167.789 39.5726L155.523 32.6997C152.668 31.2194 150.765 28.8933 149.814 25.7212C148.862 22.6549 149.073 19.5886 150.66 16.628C153.409 12.9272 156.686 10.9182 160.493 10.8125C164.194 10.7068 167.577 12.1871 170.644 15.2534L179.314 9.01495C174.45 2.56508 167.894 -0.39548 159.541 0.133198C151.188 0.767612 145.056 4.57409 141.249 11.6584C138.288 17.2624 137.654 22.9721 139.452 28.7876C141.249 34.7088 144.844 39.1497 150.448 42.0045L162.713 48.8773C165.568 50.3576 167.471 52.6838 168.423 55.7501C169.375 58.9222 169.163 61.9885 167.577 64.9491C164.828 68.6499 161.55 70.6589 157.744 70.7646C154.043 70.8703 150.66 69.39 147.593 66.3237L138.923 72.5621C143.892 79.012 150.448 81.9726 158.801 81.3382Z"
        fill="#0C1D37"
      />
      <path
        d="M232.63 54.0581V80.492H243.203V54.0581C243.203 46.7624 240.666 40.524 235.485 35.3429C230.304 30.1619 224.065 27.6242 216.769 27.6242C210.954 27.6242 205.667 29.4217 200.909 32.911V1.19031H190.335V80.492H200.909V53.4237C201.015 49.1943 202.707 45.5993 205.773 42.6387C208.839 39.6781 212.434 38.1978 216.769 38.1978C221.105 38.1978 224.911 39.7838 227.977 42.8501C231.044 45.9164 232.63 49.723 232.63 54.0581Z"
        fill="#0C1D37"
      />
      <path
        d="M261.454 35.3431C256.273 40.5241 253.735 46.7625 253.735 54.0583C253.735 61.3541 256.273 67.5925 261.454 72.7735C266.635 77.9546 272.873 80.4922 280.169 80.4922C287.465 80.4922 293.703 77.9546 298.884 72.7735C304.065 67.5925 306.603 61.3541 306.603 54.0583C306.603 46.7625 304.065 40.5241 298.884 35.3431C293.703 30.162 287.465 27.6244 280.169 27.6244C272.873 27.6244 266.635 30.162 261.454 35.3431ZM268.961 65.2663C265.894 62.1999 264.308 58.3935 264.308 54.0583C264.308 49.7231 265.894 45.9166 268.961 42.8503C272.027 39.784 275.834 38.1979 280.169 38.1979C284.504 38.1979 288.31 39.784 291.377 42.8503C294.443 45.9166 296.029 49.7231 296.029 54.0583C296.029 58.3935 294.443 62.1999 291.377 65.2663C288.31 68.3326 284.504 69.9186 280.169 69.9186C275.834 69.9186 272.027 68.3326 268.961 65.2663Z"
        fill="#0C1D37"
      />
      <path
        d="M327.709 32.9112L322.422 27.6244H317.135V106.926H327.709V75.2054C332.467 78.6947 337.754 80.4922 343.569 80.4922C350.865 80.4922 357.103 77.9546 362.284 72.7735C367.465 67.5925 370.003 61.3541 370.003 54.0583C370.003 46.7625 367.465 40.5241 362.284 35.3431C357.103 30.162 350.865 27.6244 343.569 27.6244C337.648 27.6244 332.361 29.4219 327.709 32.9112ZM354.777 42.8503C357.843 45.9166 359.43 49.7231 359.43 54.0583C359.43 58.3935 357.843 62.1999 354.777 65.2663C351.711 68.3326 347.904 69.9186 343.569 69.9186C339.234 69.9186 335.427 68.3326 332.361 65.2663C329.295 62.1999 327.709 58.3935 327.709 54.0583C327.709 49.7231 329.295 45.9166 332.361 42.8503C335.427 39.784 339.234 38.1979 343.569 38.1979C347.904 38.1979 351.711 39.784 354.777 42.8503Z"
        fill="#0C1D37"
      />
      <path
        d="M406.969 27.6244C399.673 27.6244 393.435 30.162 388.254 35.3431C383.073 40.5241 380.535 46.7625 380.535 54.0583C380.535 61.3541 383.073 67.5925 388.254 72.7735C393.435 77.9546 399.673 80.4922 406.969 80.4922C414.265 80.4922 420.503 77.9546 425.684 72.7735L418.177 65.2663C415.111 68.3326 411.304 69.9186 406.969 69.9186C402.634 69.9186 398.827 68.3326 395.761 65.2663C392.695 62.1999 391.109 58.3935 391.109 54.0583C391.109 49.7231 392.695 45.9166 395.761 42.8503C398.827 39.784 402.634 38.1979 406.969 38.1979C411.304 38.1979 415.111 39.784 418.177 42.8503L425.684 35.3431C420.503 30.162 414.265 27.6244 406.969 27.6244Z"
        fill="#FF6501"
      />
      <path
        d="M478.588 75.2054L483.875 80.4922H489.162V27.6244H478.588V32.9112C473.83 29.4219 468.543 27.6244 462.728 27.6244C455.432 27.6244 449.194 30.162 444.013 35.3431C438.832 40.5241 436.294 46.7625 436.294 54.0583C436.294 61.3541 438.832 67.5925 444.013 72.7735C449.194 77.9546 455.432 80.4922 462.728 80.4922C468.649 80.4922 473.936 78.6947 478.588 75.2054ZM451.52 65.2663C448.454 62.1999 446.868 58.3935 446.868 54.0583C446.868 49.7231 448.454 45.9166 451.52 42.8503C454.586 39.784 458.393 38.1979 462.728 38.1979C467.063 38.1979 470.87 39.784 473.936 42.8503C477.002 45.9166 478.588 49.7231 478.588 54.0583C478.588 58.3935 477.002 62.1999 473.936 65.2663C470.87 68.3326 467.063 69.9186 462.728 69.9186C458.393 69.9186 454.586 68.3326 451.52 65.2663Z"
        fill="#FF6501"
      />
      <path
        d="M510.268 27.6242V1.19031H499.694V57.9704C499.694 64.2088 501.915 69.6012 506.25 73.9364C510.585 78.2716 515.872 80.492 522.11 80.492V69.9185C518.832 69.9185 516.083 68.7554 513.757 66.4292C511.431 64.103 510.268 61.2482 510.268 57.9704V38.1978H520.841V27.6242H510.268Z"
        fill="#FF6501"
      />
      <path
        d="M85.7983 86.5437C86.0351 89.3156 83.9868 91.7576 81.2148 91.9944C78.4429 92.2312 75.999 90.1724 75.7622 87.4005C75.5254 84.6294 77.5851 82.196 80.3561 81.9592C83.1281 81.7225 85.5615 83.7717 85.7983 86.5437Z"
        fill="#FCB687"
      />
      <path
        d="M50.8842 86.6272C51.121 89.3992 49.0727 91.8412 46.3008 92.078C43.5288 92.3147 41.0849 90.256 40.8481 87.484C40.6113 84.713 42.6711 82.2796 45.4421 82.0428C48.214 81.806 50.6475 83.8553 50.8842 86.6272Z"
        fill="#FCB687"
      />
      <path
        d="M7.24792e-05 18.8654C0.010004 19.5216 0.280882 20.2395 0.819021 20.9442C2.06686 22.5645 3.78783 23.2825 5.6334 23.776C10.0731 24.9713 13.6188 27.5484 16.3195 31.4272C16.4305 31.587 16.5145 31.7629 16.612 31.9787C16.7962 32.2479 16.9587 32.5341 17.096 32.8279C17.2495 33.1313 17.3642 33.4603 17.4725 33.7798L17.7208 33.7836L17.9167 33.7817L18.2364 33.7827L18.2454 33.7836L21.8742 33.8007L22.9307 33.8121C22.6842 33.1332 22.3347 32.3905 21.9411 31.6611C21.8788 31.5432 21.8174 31.4253 21.7551 31.3169C21.6774 31.179 21.6079 31.0506 21.5293 30.9213C21.4598 30.7929 21.3894 30.6655 21.3117 30.5362L21.3126 30.5181C21.2873 30.4972 21.2729 30.4677 21.2557 30.4477C20.5605 29.2695 19.8851 28.2625 19.5908 27.8336C19.5826 27.8232 19.5844 27.8051 19.5763 27.8042L19.5853 27.7956C19.5862 27.7861 19.5781 27.7766 19.579 27.7671C19.579 27.7671 19.5691 27.7756 19.5682 27.7842C18.5948 26.5261 17.6675 25.2243 16.6364 24.0166C14.1335 21.0934 11.2234 18.6952 7.80583 17.0758C6.01443 16.2304 4.14629 15.7996 2.18875 16.3455C1.63346 16.5014 1.16936 16.741 0.82444 17.0472L0.815411 17.0463C0.270046 17.5218 -0.00444221 18.1627 7.24792e-05 18.8654Z"
        fill="#FF6501"
      />
      <path
        d="M35.4074 33.8156C35.4074 30.1496 36.1295 26.5194 37.5324 23.1324C38.9354 19.7454 40.9917 16.6679 43.584 14.0756C46.1763 11.4833 49.2538 9.42694 52.6408 8.024C56.0278 6.62106 59.658 5.89897 63.3241 5.89897C66.9901 5.89897 70.6203 6.62106 74.0073 8.024C77.3943 9.42694 80.4718 11.4833 83.0641 14.0756C85.6564 16.6679 87.7128 19.7454 89.1157 23.1324C90.5186 26.5194 91.2407 30.1496 91.2407 33.8156L63.3241 33.8156L35.4074 33.8156Z"
        fill="#FF6501"
      />
      <path
        d="M74.8997 16.1355C74.8925 14.0252 75.0001 11.9348 75.2166 9.98345C75.433 8.03214 75.754 6.25821 76.1612 4.76295C76.5684 3.26768 77.0539 2.08037 77.5898 1.2688C78.1258 0.457232 78.7018 0.0372956 79.2849 0.0329698C79.8681 0.028644 80.4469 0.440013 80.9884 1.24359C81.53 2.04717 82.0235 3.22722 82.441 4.71636C82.8585 6.20551 83.1917 7.97458 83.4215 9.92259C83.6514 11.8706 83.7734 13.9594 83.7807 16.0697L79.3402 16.1026L74.8997 16.1355Z"
        fill="#FF6501"
      />
      <path
        d="M43.5854 16.2158C43.5788 14.1055 43.6872 12.0149 43.9043 10.0634C44.1214 8.11194 44.443 6.33774 44.8507 4.84215C45.2584 3.34655 45.7442 2.15885 46.2805 1.34684C46.8167 0.534833 47.3928 0.114431 47.976 0.109635C48.5591 0.104839 49.1378 0.515744 49.679 1.31889C50.2203 2.12203 50.7135 3.30169 51.1304 4.7905C51.5474 6.27932 51.88 8.04813 52.1092 9.99595C52.3384 11.9438 52.4597 14.0325 52.4663 16.1428L48.0258 16.1793L43.5854 16.2158Z"
        fill="#FF6501"
      />
      <path
        d="M17.4668 33.8157H109.178L91.1971 79.453H35.4151L17.4668 33.8157Z"
        fill="#FCB687"
      />
    </svg>
  )
}

export default Shopcat
