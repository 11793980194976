import { RoundButton } from '@components/ui'
import { usePromptToInstall } from '@lib/usePromptToInstall'
import { FC } from 'react'
import { MdOutlineAddToHomeScreen } from 'react-icons/md'
import { InstallAppToast } from './InstallAppToast'
import cn from 'clsx'

interface Props {
  className?: string
  isProductPage?: boolean
  isNested?: boolean
}

const InstallApp: FC<Props> = ({ isProductPage, isNested }) => {
  const { deferredEvt } = usePromptToInstall()

  return (
    (deferredEvt && (
      <div className={cn(`bottom-${isNested ? 80 : isProductPage ? 80 : 40} right-2 z-[1000] h-20 w-20 sm:bottom-40 fixed`,
        { 'sm:right-[50.4%] md:right-[45.4%] xl:right-[40.4%]': isProductPage }, { 'absolute': isNested })}>
        <RoundButton
          onClick={() => deferredEvt.prompt()}
          icon={MdOutlineAddToHomeScreen}
          ariaLabel="Install mobile app"
        />
        <InstallAppToast />
      </div>
    )) ||
    null
  )
}

export default InstallApp
