import { MouseEventHandler } from 'react'
import { FaSpinner } from 'react-icons/fa'
import cn from 'clsx'

interface RoundButtonProps {
  className?: string
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  icon?: any
  loading?: boolean
  ariaLabel?: string
  dataCy?: string
}

const RoundButton = ({
  className,
  disabled = false,
  onClick,
  icon,
  loading = false,
  ariaLabel,
  dataCy,
}: RoundButtonProps) => {
  const IconComponent = icon

  return (
    <div className={className}>
      <button
        className={cn(
          disabled
            ? 'cursor-not-allowed bg-gradient-button-disabled text-[#c5c5c5]'
            : 'cursor-pointer bg-gradient-button text-white active:scale-95',
          `absolute left-2 h-16 w-16 transform-gpu rounded-full p-3 shadow-[0_5px_4px_0_rgba(0,0,0,0.26)]`
        )}
        disabled={disabled || loading}
        onClick={onClick}
        aria-label={ariaLabel}
        data-cy={dataCy}
      >
        {loading ? (
          <FaSpinner className="m-2 h-6 w-6 animate-spin text-white" />
        ) : (
          <div
            className={cn(`relative`, {
              'cursor-not-allowed': disabled,
              'transition duration-100 hover:scale-120': !disabled,
            })}
          >
            <IconComponent className="m-2 h-6 w-6 " />
          </div>
        )}
      </button>
    </div>
  )
}

export default RoundButton
