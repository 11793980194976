import { RoundButton } from '@components/ui'
import { FC, useCallback, useEffect, useState } from 'react'
import { BasketIcon } from './Basket'
import * as analytics from '@lib/analytics'
import cn from 'clsx'

interface BasketIframeProps {
  isProductPage?: boolean
}

const Basket = ({ isProductPage }: BasketIframeProps) => {
  const [itemsCount, setItemsCount] = useState(0)

  const handleMessage = useCallback((event: { data?: unknown }) => {
    if (
      event.data &&
      typeof event.data === 'string' &&
      event.data.match(/^cartCount:/)
    ) {
      const count = parseInt(event.data.replace('cartCount:', ''))

      if (!isNaN(count)) {
        setItemsCount(count)
      }
    }
  }, [])

  const checkout = useCallback(() => {
    window.parent.postMessage(`goToCart`, '*')
    analytics.track('Go to checkout', { itemsCount })
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleMessage)

    return () => window.removeEventListener('message', handleMessage)
  }, [handleMessage])

  const icon = () => <BasketIcon itemsCount={itemsCount} />

  return (!!itemsCount ? (
    <RoundButton
      className={cn('fixed right-2 bottom-20 z-[12000] h-20 w-20', { 'sm:right-[50.4%] md:right-[45.4%] xl:right-[40.4%]': isProductPage })}
      onClick={checkout}
      icon={icon}
      ariaLabel="Open basket" />
  ) : null)
}

export default Basket
