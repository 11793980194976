let _apiToken;
let _apiUrl;
export const setApiConfig = (apiToken, apiUrl)=>{
    _apiToken = apiToken;
    _apiUrl = apiUrl;
};
export const getApiConfig = ()=>_apiToken && _apiUrl ? {
        apiToken: _apiToken,
        apiUrl: _apiUrl
    } : null
;
