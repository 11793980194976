import { useState, useEffect } from 'react'
import { BsArrowDownRightSquareFill } from 'react-icons/bs'
import s from './InstallAppToast.module.css'

export const InstallAppToast = () => {
  const [isToastVisible, setIsToastVisible] = useState(false)

  useEffect(() => {
    const showToastTimer = setTimeout(() => {
      setIsToastVisible(true)
    }, 5000)

    const hideToastTimer = setTimeout(() => {
      setIsToastVisible(false)
    }, 10000)

    return () => {
      clearTimeout(showToastTimer)
      clearTimeout(hideToastTimer)
    }
  }, [])

  return isToastVisible ? (
    <div className={`absolute right-[3.3rem] bottom-[3.6rem] z-[1000] h-[50px] w-[136px] rounded-lg bg-white text-black ${s['install-toast']}`} >
      <div className={s['install-toast-text']}>Save our store as an app!</div>
      <BsArrowDownRightSquareFill className={s['install-toast-icon']} />
    </div>
  ) : null
}
