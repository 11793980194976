import SidebarLayout from '@components/common/SidebarLayout'
import { Bag, Check, Cross } from '@components/icons'
import { Button, Text } from '@components/ui'
import { useUI } from '@components/ui/context'
import useCart from '@framework/cart/use-cart'
import usePrice from '@framework/product/use-price'
import cn from 'clsx'
import Link from 'next/link'
import { FC } from 'react'
import CartItem from '../CartItem'
import s from './CartSidebarView.module.css'
import * as analytics from '@lib/analytics'

const CartSidebarView: FC = () => {
  const { closeSidebar, setSidebarView } = useUI()
  const { data, isLoading, isEmpty } = useCart()
  const { price: subTotal } = usePrice(
    data && {
      amount: Number(data.subtotalPrice),
      currencyCode: data.currency.code,
    }
  )
  const { price: total } = usePrice(
    data && {
      amount: Number(data.totalPrice),
      currencyCode: data.currency.code,
    }
  )
  const handleClose = () => closeSidebar()
  const goToCheckout = () => setSidebarView('CHECKOUT_VIEW')

  const error = null
  const success = null

  return (
    <SidebarLayout
      className={cn({
        [s.empty]: error || success || isLoading || isEmpty,
      })}
      handleClose={handleClose}
    >
      {isLoading || isEmpty ? (
        <div className="flex flex-1 flex-col items-center justify-center px-4">
          <span className="flex h-16 w-16 items-center justify-center rounded-full border border-dashed border-base-0 bg-base-900 p-12 text-base-0">
            <Bag className="absolute" />
          </span>
          <h2 className="pt-6 text-center text-2xl font-bold tracking-wide">
            Your cart is empty
          </h2>
          <p className="px-10 pt-2 text-center text-base-300">
            Biscuit oat cake wafer icing ice cream tiramisu pudding cupcake.
          </p>
        </div>
      ) : error ? (
        <div className="flex flex-1 flex-col items-center justify-center px-4">
          <span className="flex h-16 w-16 items-center justify-center rounded-full border border-white">
            <Cross width={24} height={24} />
          </span>
          <h2 className="pt-6 text-center text-xl font-light">
            We couldn’t process the purchase. Please check your card information
            and try again.
          </h2>
        </div>
      ) : success ? (
        <div className="flex flex-1 flex-col items-center justify-center px-4">
          <span className="flex h-16 w-16 items-center justify-center rounded-full border border-white">
            <Check />
          </span>
          <h2 className="pt-6 text-center text-xl font-light">
            Thank you for your order.
          </h2>
        </div>
      ) : (
        <>
          <div className="flex-1 px-4 sm:px-6">
            <Link href="/cart">
              <a>
                <Text variant="sectionHeading" onClick={handleClose}>
                  My Cart
                </Text>
              </a>
            </Link>
            <ul className={s.lineItemsList}>
              {data!.lineItems.map((item: any) => (
                <CartItem
                  key={item.id}
                  item={item}
                  currencyCode={data!.currency.code}
                />
              ))}
            </ul>
          </div>

          <div className="sticky bottom-0 right-0 left-0 z-20 w-full flex-shrink-0 border-t bg-base-0 px-6 py-6 text-sm sm:px-6">
            <ul className="pb-2">
              <li className="flex justify-between py-1">
                <span>Subtotal</span>
                <span>{subTotal}</span>
              </li>
              {/* <li className="flex justify-between py-1">
                <span>Taxes</span>
                <span>Calculated at checkout</span>
              </li> */}
              <li className="flex justify-between py-1">
                <span>Shipping</span>
                <span>Calculated at checkout</span>
              </li>
            </ul>
            <div className="mb-2 flex justify-between border-t border-base-200 py-3 font-bold">
              <span>Total</span>
              <span>{total}</span>
            </div>
            <div>
              {process.env.COMMERCE_CUSTOMCHECKOUT_ENABLED ? (
                <Button Component="a" width="100%" onClick={goToCheckout}>
                  Proceed to Checkout ({total})
                </Button>
              ) : (
                <Button
                  href="/api/checkout"
                  Component="a"
                  width="100%"
                  onClick={() => {
                    analytics.track('Go to checkout', { total })
                  }}
                >
                  Proceed to Checkout
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </SidebarLayout>
  )
}

export default CartSidebarView
